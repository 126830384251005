<template>
  <div>
    <b-row>
      <b-col
        cols="12"
      >
        <b-card>
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="9"
              class="d-flex align-items-right justify-content-start mb-1 mb-md-0"
            >
              &nbsp;
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-button
                  type="button"
                  size="sm"
                  variant="outline-primary"
                  class="d-inline-block mr-1"
                  style="margin-top:0px;"
                  :to="{name:'partner-bulk-email-new'}"
                >
                  + Neues Massenmailing
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              style="padding-top:20px;"
            >
              <b-table
                ref="bulkMailListTableRef"
                :items="tableData"
                :fields="tableColumns"
                responsive
                primary-key="id"
                class="position-relative"
                show-empty
                empty-text="No matching records found"
              >
                <template #cell(currentStatus)="data">
                  <b-badge
                    :style="getStatusColor(data.item.currentStatus)"
                  >
                    {{ getLabelForBonus(data.item.currentStatus) }}
                  </b-badge>
                </template>
                <template #cell(nextStatus)="data">
                  <b-badge
                    :style="getStatusColor(data.item.nextStatus)"
                  >
                    {{ getLabelForBonus(data.item.nextStatus) }}
                  </b-badge>
                </template>
                <template #cell(t0)="data">
                  {{ data.item.t0 | moment("DD.MM. YYYY") }}
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BBadge,
} from 'bootstrap-vue'

import {
  onUnmounted,
  ref,
} from '@vue/composition-api'

import store from '@/store'
import bonusStoreModule from '../bonus/bonusStoreModule'

import { getLabelForBonus, getStatusColor } from '../../../enums'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BBadge,
  },
  setup() {
    const BONUS_STORE_MODULE = 'bonus-store-module'
    //  Register BonusStoreModule
    if (!store.hasModule(BONUS_STORE_MODULE)) store.registerModule(BONUS_STORE_MODULE, bonusStoreModule)

    //  Unregister BonusStoreModule
    onUnmounted(() => {
      if (store.hasModule(BONUS_STORE_MODULE)) store.unregisterModule(BONUS_STORE_MODULE)
    })

    const tableData = ref([])

    const tableColumns = [
      { key: 'partner', label: 'Partner' },
      { key: 'year', label: 'Jahr' },
      { key: 'currentStatus', label: 'Status' },
      { key: 'nextStatus', label: 'Status Neu' },
      { key: 'records', label: 'menge' },
      { key: 't0', label: 'Datum' },
    ]

    store.dispatch(`${BONUS_STORE_MODULE}/bulkMailingHome`)
      .then(response => {
        tableData.value = response.data
      })

    return {
      tableData,
      tableColumns,
      getLabelForBonus,
      getStatusColor,
    }
  },
}
//
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
